import type { Grade, LinkInternal, Price } from '@backmarket/http-api'

import { createHttpEndpoint } from '../../utils'

/**
 * Recommendations collection
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/recommendation-v2-api/definition#/recommendation/get-recommendation-v2-recommendations}
 */
export const getAllRecommendations = createHttpEndpoint<
  RecommendationPayload[]
>({
  method: 'GET',
  operationId: 'recommendationCollection',
  path: '/bm/recommendation/v2/recommendations',
})

/**
 * Recommendation item
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/recommendation-v2-api/definition#/recommendation/get-recommendation-v2-recommendation}
 */
export const getRecommendationById = createHttpEndpoint<RecommendationPayload>({
  method: 'GET',
  operationId: 'recommendationItem',
  path: '/bm/recommendation/v2/recommendations/:widgetId',
})

export type RecommendationPayload = {
  recommendationRequestId: string
  widgetId: string
  products: Product[]
}

export interface Product {
  id: string
  uuid: string
  title: string
  name?: string
  displayTitle?: string
  subTitles: string[]
  brand: string
  specifications: Specification[]
  images: string[]
  category: string
  link: LinkInternal
  listing: Listing
  reviewRating?: ReviewRating
  referencePrice?: Price
}

export interface Listing {
  id: string
  price: Price
  grade: Grade
  offerType?: {
    value: number
    name: string
  }
  warranty: string
  stockWarning: number
  sellerId: string
  pricesDifference?: string
}

export interface ReviewRating {
  average: number
  count: number
}

export interface Specification {
  field: string
  values: Value[]
}

export interface Value {
  label: string
  image?: string
}
