export default {
  recommendationTag: {
    id: 'product_card_recommendation_tag',
    defaultMessage: 'Good deal',
  },

  toastSuccessTitle: {
    id: 'cross_sell_toast_title_success',
    defaultMessage: 'Hop',
  },
  toastSuccessMessage: {
    id: 'cross_sell_toast_message_success',
    defaultMessage: 'Product added to the cart.',
  },
  toastErrorTitle: {
    id: 'cross_sell_toast_title_error',
    defaultMessage: 'Oops',
  },
  toastErrorMessage: {
    id: 'cross_sell_toast_message_error',
    defaultMessage: 'Product not added to the cart.',
  },
  warrantyDescription: {
    id: 'product_card_warranty',
    defaultMessage: 'Warranty: {warranty}',
  },
  body: {
    id: 'bundle_small_to_modal_body',
    defaultMessage: 'Both for {totalPrice}',
  },
  title: {
    id: 'bundle_modal_title',
    defaultMessage: 'Often bought together',
  },
}
